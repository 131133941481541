exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-booking-services-index-js": () => import("./../../../src/pages/booking-services/index.js" /* webpackChunkName: "component---src-pages-booking-services-index-js" */),
  "component---src-pages-community-index-js": () => import("./../../../src/pages/community/index.js" /* webpackChunkName: "component---src-pages-community-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-email-confirmation-index-js": () => import("./../../../src/pages/email-confirmation/index.js" /* webpackChunkName: "component---src-pages-email-confirmation-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-forget-password-index-js": () => import("./../../../src/pages/forget-password/index.js" /* webpackChunkName: "component---src-pages-forget-password-index-js" */),
  "component---src-pages-google-redirect-index-js": () => import("./../../../src/pages/google-redirect/index.js" /* webpackChunkName: "component---src-pages-google-redirect-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-reset-password-confirmation-mail-index-js": () => import("./../../../src/pages/reset-password-confirmation-mail/index.js" /* webpackChunkName: "component---src-pages-reset-password-confirmation-mail-index-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-terms-conditions-index-js": () => import("./../../../src/pages/terms-&-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-conditions-index-js" */)
}

